<template>
  <div class="mb-4">
    <p class="mb-2 text-sm">
      You can add up to {{ maxExams }} tests
    </p>
    <ul class="flex flex-wrap space-x-2">
      <Pill
        v-for="customExam in customExams"
        :key="customExam.slug"
        :remove-option="true"
        @remove="removeCustomExam(customExam)"
      >
        {{ customExam.name }}
      </Pill>
      <Pill
        v-for="exam in exams"
        :key="exam.slug"
        :remove-option="true"
        @remove="removeExam(exam)"
      >
        {{ exam.name }}
      </Pill>
      <PillPlaceholder
        v-if="totalExams === 0"
      >
        No tests added yet
      </PillPlaceholder>
    </ul>
  </div>
</template>

<script>
import Pill from '@components/TUI/Pill'
import PillPlaceholder from '@components/TUI/PillPlaceholder'

export default {
  components: {
    Pill,
    PillPlaceholder
  },

  props: {
    exams: {
      type: Array,
      required: true
    },
    customExams: {
      type: Array,
      default: () => []
    },
    maxExams: {
      type: Number,
      required: true
    }
  },

  computed: {
    totalExams() {
      return this.exams.length + this.customExams.length
    }
  },

  methods: {
    removeExam(exam) {
      this.$emit('removeExam', exam)
    },

    removeCustomExam(exam) {
      this.$emit('removeCustomExam', exam)
    }
  }
}
</script>
