<template>
  <BaseContainer>
    <AssessmentExams />
  </BaseContainer>
</template>

<script>
import AssessmentExams from '@components/ClientAssessments/AssessmentExams'

export default {
  components: {
    AssessmentExams
  }
}
</script>
