<template>
  <li class="inline-flex rounded items-center py-1.5 pl-2.5 pr-1 text-sm font-medium bg-secondary text-white">
    <span class="truncate">
      <slot />
    </span>
    <button
      v-if="removeOption"
      type="button"
      class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-white  hover:text-gray-300 focus:outline-none focus:bg-indigo-500 focus:text-white"
      @click="remove()"
    >
      <span class="sr-only">Remove</span>
      <svg
        class="w-2 h-2"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 8 8"
      >
        <path
          stroke-linecap="round"
          stroke-width="1.5"
          d="M1 1l6 6m0-6L1 7"
        />
      </svg>
    </button>
  </li>
</template>

<script>
export default {
  props: {
    removeOption: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
